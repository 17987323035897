import * as React from 'react';
// @ts-ignore
import imgSrc from "../images/undraw_Mail_sent.svg";
import {Button} from "primereact/button";
import {Link} from "../components/Link";
import {MinimalPage} from "../containers/MinimalPage";
import {PageProps} from "gatsby";

export default function ContactSuccess(props: PageProps) {
    return <MinimalPage title={"Submission Successful"} img={imgSrc} {...props}>
        <h3>Your message has been sent.</h3>
        <p>Thank you for getting in touch.</p>
        <Link to={"/"}><Button icon={"pi pi-chevron-left"} label={"Back to site"}/></Link>
    </MinimalPage>;
}
